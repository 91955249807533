import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ReactTooltip from "react-tooltip";
import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "../components/layout";
import ImageCarousel from "../components/ImageCarousel";
import "../components/photos.css";

export const query = graphql`
  query ($slug: String!) {
    contentfulFilms(slug: { eq: $slug }) {
      title
      images {
        id
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
        description
      }
      yearMade(formatString: "YYYY")
    }
  }
`;

//set breakpoints in the imagery

export default function Photos(props) {
  return (
    <>
    {props.data.contentfulFilms.images &&
     (
    <SimpleReactLightbox>
      <Layout>
        <div className="image-carousel-container">
          <div className="mobile-image-text">(click for fullscreen)</div>
          <ReactTooltip
            className="tooltip-override"
            id="main"
            place="top"
            type="dark"
            effect="float"
            multiline={true}
          />
          <div className="title-container">
            <div className="title">
              {props.data.contentfulFilms.title.toUpperCase()}
            </div>
          </div>
          <ImageCarousel>
            {props.data.contentfulFilms.images.map((i, indx) => (
              <div key={indx} className="single-image-wrapper">
                <a
                  data-for="main"
                  data-tip="click for fullscreen &nbsp; &#10530;"
                  data-delay-show="500"
                  data-iscapture="true"
                >
                  <Img
                    fluid={i.fluid}
                    style={{
                      margin: "auto",
                      marginRight: "auto",
                      maxHeight: "100%",
                      maxWidth: "100%",
                      alignSelf: "center",
                    }}
                    imgStyle={{ objectFit: "contain" }}
                    alt={i.description}
                  />
                </a>
              </div>
            ))}
          </ImageCarousel>
        </div>
      </Layout>
    </SimpleReactLightbox>
)
}
</>
  );
}
