import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import "./imageCarouselWrapper.css";
import NextIcon from "../assets/next.svg";
import PreviousIcon from "../assets/previous.svg";

const options = {
  buttons: {
    backgroundColor: "black",
    iconColor: "white",
    showDownloadButton: false,
    showAutoplayButton: false,
    showThumbnailsButton: false
  },
  settings: {
    overlayColor: "black",
    transitionSpeed: 1000,
    transitionTimingFunction: "linear",
  },
  thumbnails: {
    thumbnailsSize: ["90px", "70px"],
    thumbnailsOpacity: 0.4,
    // showThumbnails: true
  },
  caption: {
    captionColor: "#808080",
    captionFontFamily: "Neue Montreal",
    captionFontSize: "10px"
  },
  progressBar: {
    size: "4px",
    backgroundColor: "black",
    fillColor: "#AF9AB2",
  },
};

export default function ImageCarousel({ children }) {
  const { openLightbox } = useLightbox();
  const [currentSlide, setCurrentSlide] = useState(0);

  function next() {
    setCurrentSlide(currentSlide + 1);
  }

  function previous() {
    setCurrentSlide(currentSlide - 1);
  }

  function updateCurrentSlide(index) {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  }
  return (
    <div className="photo-content-wrapper">
      <div className="photo-counter">
        {currentSlide + 1 + " / " + children.length}
      </div>
      {currentSlide !== children.length - 1 && (
        <div className="next-button" onClick={next}>
          <img src={NextIcon} />
        </div>
      )}
      {currentSlide !== 0 && (
        <div className="previous-button" onClick={previous}>
          <img src={PreviousIcon} />
        </div>
      )}
      <div className="photo-carousel-wrapper">
        <SRLWrapper options={options}>
          <Carousel
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            onClickItem={(indx) => openLightbox(indx)}
            showThumbs={false}
          >
            {children}
          </Carousel>
        </SRLWrapper>
      </div>
    </div>
  );
}
