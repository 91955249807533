import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "./header.css";

const activeStyle = {
  color: "#8c84f7",
  fontFamily: "Neue Montreal Bold Italic",
  textDecoration: "none",
};

const Header = ({ siteTitle }) => (
  <>
    <header className="desktop-nav-container">
      <div className="nav-items">
        <div className="nav-spacer"></div>
        <div>
          <Link to="/films" className="link" activeStyle={activeStyle}>
            FILMS
          </Link>
        </div>
        <div className="nav-spacer"></div>
        <div>
          <Link to="/photos" className="link" activeStyle={activeStyle}>
            PHOTOS
          </Link>
        </div>
        <div className="nav-spacer"></div>
        <div>
          <Link to="/about" className="link" activeStyle={activeStyle}>
            ABOUT
          </Link>
        </div>
        <div className="nav-spacer"></div>
        <div>
          <Link to="/contact" className="link" activeStyle={activeStyle}>
            CONTACT
          </Link>
        </div>
      </div>
      <Link to="/" className="header-logo">
        LOUIS-JACK
      </Link>
    </header>
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
