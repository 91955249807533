import React, { useState } from "react";
import { navigate } from "gatsby";
import "./header.css";
import nav from "../assets/nav.svg";
import close from "../assets/close-nav.svg";

const activeStyle = {
  color: "#8c84f7",
  fontFamily: "Neue Montreal Bold Italic",
  textDecoration: "none",
};

export default function MobileNav() {
  const [open, setOpen] = useState(false);

  function closeAndNavigate(route) {
    setOpen(!open);
    const navigateTo = setTimeout(() => {
      navigate(route);
    }, 500);
    return () => clearTimeout(navigateTo);
  }

  return (
    <>
      <div className="header-logo-mobile">LOUIS-JACK</div>
      <div className={open ? "close-icon open-nav" : "close-icon"} onClick={() => setOpen(!open)}>
        <img src={open ? close : nav} />
      </div>
      <div
        className={open ? "close-container open" : "close-container"}
        onClick={() => setOpen(!open)}
      />
      <div
        className={open ? "mobile-nav-menu open" : "mobile-nav-menu"}
        onClick={() => setOpen(!open)}
      >
        <p
          className="link"
          activeStyle={activeStyle}
          onClick={() => closeAndNavigate("/films")}
        >
          FILMS
        </p>
        <p
          className="link"
          activeStyle={activeStyle}
          onClick={() => closeAndNavigate("/photos")}
        >
          PHOTOS
        </p>
        <p
          className="link"
          activeStyle={activeStyle}
          onClick={() => closeAndNavigate("/about")}
        >
          ABOUT
        </p>
        <p
          className="link"
          activeStyle={activeStyle}
          onClick={() => closeAndNavigate("/contact")}
        >
          CONTACT
        </p>
      </div>
    </>
  );
}
